import React, {
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import ReactRouterPrompt from 'react-router-prompt';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Form,
  FormSpy,
} from 'react-final-form';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { set } from 'date-fns';
import slugify from 'slugify/slugify';
import {
  Container,
  CircularProgress,
  Fab,
  Grid,
  Tooltip,
  makeStyles,
  Box
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { SLUGIFY_REGEX } from 'src/utils/consts';
import {
  clearNewsData,
  createNews,
  deleteNews,
  fetchNewsDetails,
  updateNews,
} from 'src/store/actions/news';
import newsSelectors from 'src/store/selectors/news';

import AlertDialog from 'src/components/AlertDialog';
import Loading from 'src/components/Loading';
import Gallery from 'src/components/Gallery';
import Page from 'src/components/Page';
import NewsDetails from './NewsDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(3)
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark,
    }
  },
  floatingActionButton: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(4),
  },
}));

// Validation
const schema = Yup.object().shape({
  title: Yup.string()
    .required('Pole jest wymagane'),
  language: Yup.string()
    .required('Pole jest wymagane'),
  content: Yup.string()
    .required('Pole jest wymagane'),
  date: Yup.date()
    .nullable()
    .typeError('Nieprawidłowy format daty')
    .max(set(new Date(), { hours: 23, minutes: 59, seconds: 59 }), 'Podana data musi być w przeszłości'),
  slug: Yup.string()
    .required('Pole jest wymagane'),
  status: Yup.string()
    .required('Pole jest wymagane'),
});

const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;

const NewsDetailView = ({ action }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const classes = useStyles();
  const deleteAlertRef = useRef();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const article = useSelector((state) => newsSelectors.getNewsDetails(state));
  const isFetching = useSelector((state) => newsSelectors.getNewsDetailsIsFetching(state));

  const [galleryItems, setGalleryItems] = useState([]);

  const language = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('language') || 'pl';
  }, [location.search]);

  const parseGallery = (files) => {
    const gallery = files.map((file) => {
      return {
        id: file.id,
        data: `${STORAGE_URL}/news/${id}/${file.filename}`,
        file: {
          name: file.filename,
        },
        order: file.order,
      };
    });

    setGalleryItems(gallery);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (action === 'edit') {
        const data = await dispatch(fetchNewsDetails(id));
        parseGallery(data.files);
      }
    };

    fetchData()
      .catch(() => {
        enqueueSnackbar('Wystąpił problem podczas pobierania danych', { variant: 'error' });
      });

    return () => {
      dispatch(clearNewsData());
    };
  }, [action, dispatch, enqueueSnackbar, id, language]);

  const createSlug = (title) => {
    return slugify(title, {
      lower: true,
      remove: SLUGIFY_REGEX,
    });
  };

  const getSubmitIcon = (status) => {
    if (action === 'add') {
      return <AddIcon />;
    }

    if (status === 'deleted') {
      return <DeleteIcon />;
    }

    return <SaveIcon />;
  };

  const getSubmitLabel = (status) => {
    if (action === 'add') {
      return 'Dodaj';
    }

    if (status === 'deleted') {
      return 'Usuń';
    }

    return 'Zapisz zmiany';
  };

  const onSubmit = async (values) => {
    if (values.status === 'deleted') {
      deleteAlertRef.current.open();
      return false;
    }

    values.slug = createSlug(values.slug);

    const formData = new FormData();

    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      formData.append(key, value);
    });

    if (galleryItems.length > 0) {
      const filesToRemove = [];

      galleryItems.forEach((item) => {
        if (!item.deleted) {
          if (item.file.path) {
            formData.append('gallery', item.file);
          }
        } else {
          filesToRemove.push(item.id);
        }
      });

      formData.append('galleryOrder', galleryItems.map(({ deleted, file }) => !deleted && file.name));

      if (filesToRemove.length > 0) {
        formData.append('filesToRemove', filesToRemove.join(','));
      }
    }

    try {
      if (action === 'edit') {
        const data = await dispatch(updateNews(id, formData));
        parseGallery(data.files);
        enqueueSnackbar('Zmiany zostały zapisane', { variant: 'success' });
      } else {
        await dispatch(createNews(formData));
        enqueueSnackbar('Dodano nowy artykuł', { variant: 'success' });

        setTimeout(() => {
          navigate('/news');
        });
      }

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  const onDelete = async () => {
    try {
      await dispatch(deleteNews(id));
      enqueueSnackbar('Artykuł został usunięty', { variant: 'success' });

      setTimeout(() => {
        navigate('/news');
      });

      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message || 'Wystąpił błąd podczas wysyłania danych', { variant: 'error' });

      return error;
    }
  };

  return (
    <Form
      initialValues={
        article ? {
          title: article.title ?? '',
          type: article.type,
          content: article.content ?? '',
          date: article.date ?? '',
          language: article.language,
          slug: article.slug ?? '',
          status: article.status,
        } : {
          date: new Date(),
          status: 'public',
        }
      }
      mutators={{
        setContent: (args, state, utils) => {
          utils.changeValue(state, 'content', () => args[0]);
        },
        setSlug: (args, state, utils) => {
          const slug = createSlug(args[0]);
          if ((action === 'add' || (action === 'edit' && !article.title)) && !state.fields.slug.modified) {
            utils.changeValue(state, 'slug', () => slug);
          }
        },
      }}
      onSubmit={onSubmit}
      subscription={{
        dirty: true,
        submitSucceeded: true,
      }}
      validate={makeValidate(schema)}
      render={({
        dirty,
        form,
        handleSubmit,
        submitSucceeded,
      }) => (
        <Page
          appBar={{
            title: action === 'edit' ? 'Edycja aktualności' : 'Dodaj nową aktualność',
            hasBackButton: true,
            backTo: '/news',
          }}
          className={classes.root}
          title={
            action === 'edit'
              ? `Edycja aktualności${article ? `: ${article.title ?? ''} [ID: #${article.id}]` : ''}`
              : 'Dodaj nową aktualność'
          }
        >
          {(action === 'add' || article) ? (
            <form onSubmit={handleSubmit} noValidate>
              <Container maxWidth="lg">
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <NewsDetails
                      data={article}
                      setContent={form.mutators.setContent}
                      setSlug={form.mutators.setSlug}
                    />
                  </Grid>
                </Grid>
                <FormSpy
                  subscription={{
                    submitting: true,
                    values: true,
                  }}
                >
                  {({ submitting, values }) => (
                    <Tooltip
                      placement="left"
                      title={getSubmitLabel(values.status)}
                    >
                      <Fab
                        aria-label="save"
                        className={clsx(
                          classes.floatingActionButton,
                          values.status === 'deleted' && classes.deleteButton,
                        )}
                        color="secondary"
                        disabled={submitting}
                        type="submit"
                      >
                        {!submitting
                          ? getSubmitIcon(values.status)
                          : <CircularProgress color="secondary" size={26} />}
                      </Fab>
                    </Tooltip>
                  )}
                </FormSpy>
                <Box mt={3}>
                  <Gallery
                    items={galleryItems}
                    setItems={setGalleryItems}
                  />
                </Box>
              </Container>
              <ReactRouterPrompt when={dirty && !submitSucceeded}>
                {({ isActive, onConfirm, onCancel }) => (
                  <AlertDialog
                    title="Nie zapisano zmian"
                    message="Czy pewno chcesz opuścić stronę bez zapisywania zmian?"
                    isOpen={isActive}
                    actions={[
                      {
                        label: 'Wyjdź bez zapisywania',
                        onClick: onConfirm,
                      },
                      {
                        autoFocus: true,
                        label: 'Wróć',
                        variant: 'contained',
                        onClick: onCancel,
                      },
                    ]}
                  />
                )}
              </ReactRouterPrompt>
              <AlertDialog
                title="Czy na pewno chcesz usunąć tą stronę?"
                ref={deleteAlertRef}
                actions={[
                  {
                    label: 'Wróć',
                  },
                  {
                    className: classes.deleteButton,
                    disabled: isFetching,
                    label: 'Usuń',
                    loading: isFetching,
                    onClick: () => {
                      onDelete();
                      deleteAlertRef.current.close();
                    },
                  },
                ]}
              />
            </form>
          ) : (
            <Loading />
          )}
        </Page>
      )}
    />
  );
};

NewsDetailView.propTypes = {
  action: PropTypes.string,
};

NewsDetailView.defaultProps = {
  action: 'add',
};

export default NewsDetailView;
